export const environment = {
  production: true,
  baseHref: '/',
  glorifireUrl: 'https://beta.glorifire.com/',
  myPatUrl: 'https://uat.mypat.in',
  myPatUrlv4: 'https://beta.glorifire.com/',
  fiitjeeUrlv3: 'https://uat-fiitjee-v3.mypat.in',
  fiitjeeUrlv4: 'https://uat-fiitjee.mypat.in',
  serverURLv4: 'https://uat-v4-auth-service.mypat.in/',
  serverURLv3: 'https://uat-api.mypat.in/',
  mockServerURL: 'https://uat-api-mock.mypat.in/',
  fbAppId: '1619315841714236',
  google_clientId: '544027600908-fl76h9b7sq949f3m9co5pnr9805nd61a.apps.googleusercontent.com',
  partnerDomain: 'https://uat-fiitjee.mypat.in/auth/admin-access/',
  razorPayKey: 'rzp_test_qMb3Ka63Y6heW9',
  clevertapId: 'TEST-798-8W8-545Z',
  GCM_Sender_key: '189667902791',
  GCM_server_API_key: 'AIzaSyAbXH2Ah7tkUZC9VfjtRfDVFJHQsH9poSE',
  kaptureCrmKey: 'Basic aDM3b2FtcmtjYzY1dHhzbzl6bDFmM2hoNnNodWF2cnNsenM5bGJuaGN1M3NqYnowdGg=',
  name: 'staging',
  captchaKey: '6LdURVEUAAAAADSnZK0cJacinv-PqlTO_7K-CQkU',
  zestMoneyMerchantId: '89cad954-b7ea-47a9-af2d-8c9a0027357f',
  zestMoneyPaymentGatewayId: '31a2b66b-f364-48f9-a7fd-6870a5a224b4',
  teacherSiteURL: 'https://uat-teachersv3.mypat.in/login',
  schoolPartnerDomain: 'https://uat-schools.mypat.in/',
  teacherServerURL: 'https://uat-schoolpat.mypat.in/',
  s3BucketBaseUrl: 'https://s3.us-west-2.amazonaws.com/mypat-v2-staging/',
  glorifireCommunity: 'https://community.glorifire.com',
  glorifireliveClasses: 'https://liveclasses.glorifire.com',
  gtmId: 'GTM-NHSSJKV',
  fingerprintJsKey: 'V5ebyiwdj0kjhUKkCrCj',
  authors: 'https://beta-authors.glorifire.com',
    glorifireJr: 'https://junior.glorifire.com/',
  helpGuidePdfId: '66338b417d066b471dda531d',
  aitsRedirectUrl: 'https://fiitje-aits.mypat.in',
};

