import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class KnowledgeGraphService {
  public graphActiveState: any = {};
  public buildGraph: Subject<any> = new Subject();
  public activeNode: Subject<any> = new Subject();
  public graphPosition: Subject<any> = new Subject();

  constructor(private httpClient: HttpClient, private httpService: HttpService) { }

  getContentList(payload: any) {
    return this.httpService.post('v4/auth/knowledge-graph/list', true, payload);
  }
  getQuestionList(courseId: string, chapterId: string, conceptId: string, attemptType: string = '') {
    return this.httpService.get(`v4/auth/knowledge-graph/course/${courseId}/chapter/${chapterId}/concept/${conceptId}/questions?attemptStatus=${attemptType}`, true, null, true);
  }
  getUserGoal() {
    return this.httpService.get('v4/auth/users/courses', true, null, true);
  }
}
