const VIEW = {
    RELATIVE: {
        LABEL: 'Relative Performance',
        ID: 'relative',
    },
    ABSOLUTE: {
        LABEL: 'Absolute Performance',
        ID: 'absolute'
    },
};

const CONTENT_TYPE = {
    SUBJECT: 'subject',
    CHAPTER: 'chapter',
    CONCEPT: 'concept',
    GOAL: 'goal',
}

const NODE_CHILD = {
    [CONTENT_TYPE.GOAL]: CONTENT_TYPE.SUBJECT,
    [CONTENT_TYPE.SUBJECT]: CONTENT_TYPE.CHAPTER,
    [CONTENT_TYPE.CHAPTER]: CONTENT_TYPE.CONCEPT,
};

const NODE_DATA = {
    ROOT: {
        //BACKGROUND: '#0611550D',
        DIMENSION: {
            width: 272,
            height: 62,
        }
    },
    CHILD: {
        DIMENSION: {
            width: 242,
            height: 57,
        }
    },
    SUBJECT: {
        MIN_X: 131,
        MAX_X: 200,
    },
    CONCEPT: {
        MIN_X: 195,
        MAX_X: 257,
    }
};

const GRAPH_PARAMS = {
    VIEW,
    CONTENT_TYPE,
    NODE_DATA,
    NODE_CHILD
};

export default GRAPH_PARAMS;