<div class="tree-container" (keydown)="onKeyDown($event)" tabindex="0" [ngClass]="{'full-screen':fullMode}">
  <button class="full-mode-btn" (click)="enableFullMode()"> {{fullMode ?'Exit':''}} Full Screen
    <img [src]="'/assets/images/' + (!fullMode ? 'full-screen.gif':'exit-full-screen.svg')" alt="Full Screen" />
  </button>
   <div class="zoom">
    <button (click)="zoomIn()">+</button>
    <button (click)="zoomOut()">-</button>
  </div>

    <div class="tree-pane" (mousedown)="onmousedown()"
    (mouseup)="onmouseup()"
    (mousemove)="onmousemove($event)"
    (mousewheel)="onmousewheel($event)"  tabindex="0">
      <div class="tree-paning-container" [style.transform]="paneTransform">
        <div class="tree-roots-elements">
          <div class="start-block"></div>
          <div class="slips" *ngIf="!visible; else graphView"></div>
          <ng-template #graphView>
            <tree-diagram-node  [nodesSrv]="nodesSrv" [nodeId]="node.guid" class="tree-root tree-node" (mousedown)="preventMouse($event)"
            *ngFor="let node of nodes.roots" (nodeClick)="nodeClick($event)"></tree-diagram-node>
          </ng-template>
          <div class="end-block" #graphEnd></div>
      </div>
    </div>
    <div class="move-arrow">
      <button (click)="moveRight()"><img class="left" src="/assets/images/swot/arrow-down.svg" alt="Left" /></button>
      <button (click)="moveLeft()"><img class="right" src="/assets/images/swot/arrow-down.svg" alt="Right" /></button>
    </div>
  </div>
