const ERROR_MESSAGE = {
    GOALS: 'Unable to get User goals',
    CONTENT: 'Unable to get Content list',
    SUBJECT: 'Unable to get Subjects',
    QUESTION: 'Unable to get question',
};

const UTILITY_PRAMS = {
    SUBJECT_LENGTH: 15,
    DEFAULT_LENGTH: 20,
    ELLIPSE: '...',
    MIN_SKILL: 4,
    MAX_SKILL: 14,
    MAX_TITLE: 30,
    MAX_CONCEPT: 300,
    ALL: {
        name: 'All Concepts',
        KEY: 'all',
    },
    CARDS: ['blueCard', 'greenCard', 'orangeCard', 'redCard', 'purpleCard'],
};

const QUESTION_ATTEMPT_TYPES = {
    CORRECT: 'correct',
    INCORRECT: 'incorrect',
    PARTIALLY_CORRECT: 'partially-correct',
    NOT_ATTEMPTED: 'not-attempted',
};

const KG_PRAMS = {
    ERROR_MESSAGE,
    UTILITY_PRAMS,
    QUESTION_ATTEMPT_TYPES,
};

export default KG_PRAMS;