<div class="line-to"></div>
<div id="tree-node-{{ node.guid }}"
  [ngClass]="{'tree-element-container': !0, 'tree-element-has-children': node.isChildrenPresent}"
  [style.width.px]="node.width" [style.height.px]="node.height">
  <div class="tree-element-main" [ngClass]="{'tree-new-node': node.isMaker}"
     [style.width.px]="node.width" [style.height.px]="node.height">
    <div class="rect graph-tree-view-sec {{node?.capability?.value}}" 
    [ngClass]="{'active':node.toggleState, 'goal-content':node.type === CONTENT_TYPE.GOAL, 'no-content': (node.type === CONTENT_TYPE.GOAL) || (node.type === CONTENT_TYPE.SUBJECT && !node.chapterCount) || (node.type === CONTENT_TYPE.CHAPTER && !node.conceptCount) || (node.type === CONTENT_TYPE.CONCEPT && !node?.distinctQuestionStats?.totalQuestionCount )}" 
    [style.width.px]="node.width" [style.height.px]="node.height"
      [style.backgroundColor]="node?.capability?.background">
      <div class="graph-tree-view-full-click" (click)="toggleState()"></div>
      <div class="tree-text tree-text-editable">
        <div class="graph-subject-info-view" (blur)="onNodeBlur($event, node.guid)">
          <ng-container [ngSwitch]="node.type">
            <div class="root-content" *ngSwitchCase="CONTENT_TYPE.GOAL">
              <img class="graph-subject-icon" [src]="node.courseImage" alt="Goal" />
              <div class="graph-subject-info">
                <h3 class="subject-name">{{node.displayName}}</h3>
              </div>
            </div>
            <ng-container *ngSwitchCase="CONTENT_TYPE.SUBJECT">
              <img class="graph-subject-icon" [src]="node?.icons?.web" alt="Subject" />
              <div class="graph-subject-info" [ngClass]="{'chapterCount': node.chapterCount}">
                <h3 class="subject-name mb-1">{{node.displayName}}</h3>
                <h4 class="chaptar-view mb-1">{{node.chapterCount}} Chapter{{node.chapterCount!==1?'s':''}}</h4>
              </div>
              <h5 class="status-view" [style.color]="node?.capability?.textColor">{{ node.capability?.value}} </h5>

            </ng-container>
            <ng-container *ngSwitchCase="CONTENT_TYPE.CHAPTER">
              <div class="graph-subject-info">
                <p class="chapter-detail-text mb-0" [ngClass]="{'wrap-text':node.displayName?.length > TRANSFORM_CONFIG.BREAK_LENGTH && !node.viewMore}">{{node.sno}}.
                  <span *ngIf="node.displayName?.length > TRANSFORM_CONFIG.MAX_LENGTH && !node.viewMore; else fullName">{{node.shortName}}...</span>
                   <span class="view-more" *ngIf="node.displayName?.length > TRANSFORM_CONFIG.MAX_LENGTH" (click)="moreLessToggle($event,node)"> 
                    <ng-container *ngIf="!node.viewMore then viewMore else viewLess"></ng-container>
                   <ng-template #viewMore>View More</ng-template>
                   <ng-template #viewLess> &nbsp;View Less</ng-template>
                  </span>
                </p>
              </div>
              <h5 class="status-view" [style.color]="node.capability?.textColor">{{node.capability?.value}} </h5>

            </ng-container>
            <ng-container *ngSwitchCase="CONTENT_TYPE.CONCEPT">
              <div class="graph-subject-info">
                <p class="chapter-detail-text mb-0" [ngClass]="{'wrap-text':node.displayName?.length > TRANSFORM_CONFIG.BREAK_LENGTH && !node.viewMore}">{{node.sno}}.
                  <span *ngIf="node.displayName?.length > TRANSFORM_CONFIG.MAX_LENGTH && !node.viewMore; else fullName">{{node.shortName}}...</span>
                   <span class="view-more" *ngIf="node.displayName?.length > TRANSFORM_CONFIG.MAX_LENGTH" (click)="moreLessToggle($event,node)"> 
                    <ng-container *ngIf="!node.viewMore then viewMore else viewLess"></ng-container>
                   <ng-template #viewMore>View More</ng-template>
                   <ng-template #viewLess> &nbsp;View Less</ng-template>
                  </span>
                </p>
              </div>
              <h5 class="status-view" [style.color]="node.capability?.textColor">{{node.capability?.value}}
                 <svg *ngIf="node?.questionStats?.totalQuestionCount" viewBox="0 0 7 12" fill="none">
                  <path [attr.fill]="node.capability?.textColor" fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.292893 11.7071C-0.097631 11.3166 -0.097631 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976313 1.31658 -0.0976313 0.683418 0.292893 0.292894C0.683417 -0.0976313 1.31658 -0.0976313 1.70711 0.292894L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071Z" />
                </svg></h5>

            </ng-container>
          </ng-container>
        </div>
      </div>
      <ng-container [ngSwitch]="node.type">
        <div (click)="toggleState()">
          <ng-container *ngSwitchCase="CONTENT_TYPE.SUBJECT">
            <span class="items-count">
              <ng-container *ngIf="node.toggleState; else chapterCountTpl"><img src="/assets/images/minus-line.svg" alt="Minus" /></ng-container>
              <ng-template #chapterCountTpl>{{node.chapterCount}}</ng-template>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="CONTENT_TYPE.CHAPTER">
            <span class="items-count" *ngIf="node.conceptCount">
              <ng-container *ngIf="node.toggleState; else conceptCountTpl"><img src="/assets/images/minus-line.svg" alt="Minus" /></ng-container>
              <ng-template #conceptCountTpl>{{node.conceptCount}}</ng-template>
            </span>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <ul class="tooltip-hover-view" [ngClass]="{'d-none':node.type !== CONTENT_TYPE.CONCEPT}">
      <li>Total Attempts: <strong>{{node?.distinctQuestionStats?.totalQuestionCount}}</strong></li>
      <li>Correct: <strong>{{node?.distinctQuestionStats?.correctCount}}</strong></li>
      <li>Partially Correct: <strong>{{node?.distinctQuestionStats?.partiallyCorrect}}</strong></li>
      <li>Incorrect: <strong>{{node?.distinctQuestionStats?.incorrectCount}}</strong></li>
      <li>Not Attempted: <strong>{{node?.distinctQuestionStats?.notAttemptedCount}}</strong></li>
    </ul>
  </div>
  <div class="tree-children" [style.transform]="childrenTransform" *ngIf="node.isExpanded">
    <div class="tree-elements-group">
      <tree-diagram-node *ngFor="let child of node.children" [nodesSrv]="nodesSrv" [nodeId]="child"
        class="tree-node tree-child"></tree-diagram-node>
    </div>
  </div>
</div>

<ng-template #fullName>{{node.displayName}}</ng-template>