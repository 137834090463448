const TRANSFORM_CONFIG = {
    LEFT_ARROW: 'ArrowLeft',
    RIGHT_ARROW: 'ArrowRight',
    UP_ARROW: 'ArrowUp',
    DOWN_ARROW: 'ArrowDown',
    MOVE: 100,
    THRESHOLD_UP: 400,
    THRESHOLD_DOWN: -100,
    MAX_ZOOM: 2,
    ZOOM_LEVEL: 0.25,
    MAX_LENGTH: 58,
    SHORT_NAME_LENGTH: 45,
    BREAK_LENGTH: 40,
};

export default TRANSFORM_CONFIG;