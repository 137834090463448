import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AlertService } from '../../shared/services/alert.service';
import { Observable, ObservableInput } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import _ from 'lodash';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  constructor(private http: HttpService, private alert: AlertService) {
  }

  public solutionFilterData = new BehaviorSubject<any>({});
  public sideFilterDetails = new BehaviorSubject<any>({});
  public filterSelected = new BehaviorSubject<any>({});
  public isFilterListLoading = new BehaviorSubject<boolean>(true);
  public isSolutionListLoading = new BehaviorSubject<boolean>(true);
  public hideFilterForSubjectiveCPP = new BehaviorSubject<boolean>(false);
  public allMistakes = new BehaviorSubject<any>([]);

  private tabSelectedSubject = new BehaviorSubject<any>({
    name: 'subjective',
    hasSectionUpdate: false,
  });
  tabSelected: Observable<any> = this.tabSelectedSubject.asObservable();
  updateTabSelected(newValue: any) {
    this.tabSelectedSubject.next(newValue);
  }
  
  getDataForSolution(dataToPass) {
    this.http.post('solutionStats', true, dataToPass, false, false, false, environment.serverURLv3).subscribe((res) => {
      if (res) {
        this.solutionFilterData.next(res);
        this.isSolutionListLoading.next(false);
        this.isFilterListLoading.next(false);
        // this.cleverTap.solutionViewed();
      }
    });
  }

  sendSolutionVisitTime(dataToPass) {
    this.http.post('solutionVisitTime', true, dataToPass, false, false, false, environment.serverURLv3).subscribe((res) => {
      if (res.code === 200) {
      }
    });
  }

  sendQuestionVisitTime(dataToPass) {
    this.http.post('questionVisitTime', true, dataToPass, false, false, false, environment.serverURLv3).subscribe((res) => {
      if (res.code === 200) {
      }
    });
  }

  updateRatings(obj) {
    if (obj.type.toLowerCase() === 'question') {
      this.http.post('rateQuestion', true, obj, true, false, false, environment.serverURLv3).subscribe((res) => {
        if (res.code === 200) {
          this.alert.showSuccessMessage('Your response has been submitted sucessfully');
          $('rateQuestion').modal('hide');
        }
      });
    } else {
      this.http.post('rateSolution', true, obj, true, false, false, environment.serverURLv3).subscribe((res) => {
        if (res.code === 200) {
          this.alert.showSuccessMessage('Your response has been submitted sucessfully');
          $('rateQuestion').modal('hide');
        }
      });
    }
  }

  submitMistakes(obj) {
    this.http.post('markQuestionMistakes', true, obj, true, false, false, environment.serverURLv3).subscribe((res) => {
      if (res.code === 200) {
        this.alert.showSuccessMessage('Your response has been submitted sucessfully');
      }
    });
  }

  bookMarkAQuestion(obj) {
    this.http.post('bookmarkQuestion', true, obj, true, false, false, environment.serverURLv3).subscribe((res) => {
      if (res.code === 200) {
        this.alert.showSuccessMessage('Question Bookmarked sucessfully');
      }
    });
  }

  submitYourSuggestion(obj) {
    return new Promise((resolve, reject) => {
      this.http.post('questionSuggestion', true, obj, true, false, false, environment.serverURLv3).subscribe((res) => {
        if (res.code === 200) {
          this.alert.showSuccessMessage('Thank you for your valuable feedback. We will shortly get back to you with a resolution');
          return resolve(res.code);
        } else {
          this.alert.showErrorMessage(res?.message?.message);
        }
      }, (error) => this.alert.showErrorMessage(error.message));
    });
  }

  setSolutionListLoading(value) {
    this.isSolutionListLoading.next(value);
  }

  resetData() {
    this.solutionFilterData.next({});
  }

  public getQuestionTags(): Observable<any> {
    return this.http.get('api/v3/userTag', true, null, true, false, environment.serverURLv3);
  }

  public saveQuestionTag(postBody: {}): Observable<any> {
    return this.http.post('api/v3/userTag', true, postBody, false, false, false, environment.serverURLv3);
  }

  public deleteCustomTag(tagId: string): Observable<any> {
    return this.http.delete(`api/v3/userTag/${tagId}`, true, null, environment.serverURLv3);
  }
f
  public getOneQuestionTag(tagId: string): Observable<any> {
    return this.http.get(`api/v3/userTag/${tagId}`, true, null, false, false, environment.serverURLv3);
  }

  public updateTagInformation(tagId: string, postBody: {}): Observable<any> {
    return this.http.put(`api/v3/userTag/${tagId}`, true, postBody, false, environment.serverURLv3);
  }

  public addRevisionQuestion(postBody: {}): Observable<any> {
    return this.http.post('api/v3/revision', true, postBody, false, false, false, environment.serverURLv3);
  }

  public getRevisionQuestion(questionId: string, attemptId?: string): Observable<any> {
    return this.http.get(`api/v3/revision/questions/${questionId}${attemptId?'/attempts/'+attemptId:''}`, true, null, false, false, environment.serverURLv3);
  }

  public removeRevisionQuestion(questionId: string): Observable<any> {
    return this.http.delete(`api/v3/revision/questions/${questionId}`, null, true, environment.serverURLv3);
  }

  public getDeleteQuestionCount(tagId: string): Observable<any> {
    return this.http.get(`api/v3/revision/filterAndCount?tagIds=${tagId}`, true, null, false, false, environment.serverURLv3);
  }
  public fetchAllMistakeTags(){
    const apiUrl = 'question-mistake-tags';
    return new Promise((resolve,reject)=>{
     this.http.get(apiUrl, true, null, true,false,environment.serverURLv3).subscribe(res => {
      let questionMistakeTags=[];
      if(res.code==200){
           questionMistakeTags=_.get(res,"data.questionMistakeTags",[]);
      }
      this.allMistakes.next(questionMistakeTags);
      resolve(questionMistakeTags);
    })});
  }
  public addCustomMistake(postBody:{name:String}){
    const apiUrl = 'question-mistake-tags';
    return new Promise((resolve,reject)=>{
     this.http.post(apiUrl, true, postBody,false,true,false,environment.serverURLv3).subscribe(res => {
      resolve(res);
    })});
  }

}
