import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NodesListService } from '../services/nodes-list.service';
import { TreeDiagramNode } from '../classes/node.class';
import { TreeDiagramNodeMaker } from '../classes/node-maker.class';
import { KnowledgeGraphService } from '../../knowledge-graph.service';
import GRAPH_PARAMS from '../../graph-view/graph-view.constants';
import { Subscription } from 'rxjs';
import TRANSFORM_CONFIG from '../tree.constant';

@Component({
  selector: 'tree-diagram-node',
  styleUrls: ['./node.component.scss'],
  templateUrl: './node.component.html'
})
export class NodeComponent implements OnInit, OnDestroy {
  public node: any;
  public childrenTransform;
  private readonly isRtl: boolean;
  public isActive: boolean = false;
  public readonly CONTENT_TYPE = GRAPH_PARAMS.CONTENT_TYPE;
  public readonly TRANSFORM_CONFIG = TRANSFORM_CONFIG;
  private activeSubscriptions: Subscription = new Subscription();
  @Input() nodesSrv: NodesListService;

  constructor(
    private sanitizer: DomSanitizer,
    private knowledgeGraphService: KnowledgeGraphService
  ) {
    this.isRtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
  }

  ngOnInit(): void {
    this.activeSubscriptions.add(
      this.knowledgeGraphService.activeNode.subscribe(res => {
        let toggleNode: any = false;
        toggleNode += res.type === GRAPH_PARAMS.CONTENT_TYPE.GOAL && this.node.type === GRAPH_PARAMS.CONTENT_TYPE.SUBJECT;
        toggleNode += res.type === GRAPH_PARAMS.CONTENT_TYPE.SUBJECT && this.node.type === GRAPH_PARAMS.CONTENT_TYPE.CHAPTER;
        toggleNode += res.type === GRAPH_PARAMS.CONTENT_TYPE.CHAPTER && this.node.type === GRAPH_PARAMS.CONTENT_TYPE.CHAPTER;
        if (res.id === this.node.id) {
          this.getChildData();
        } else {
          toggleNode && this.node.toggleState && this.node.toggle();
        }
      }));
  }

  @Output() nodeClick: EventEmitter<any> = new EventEmitter();
  @Input() set nodeId(guid) {
    this.node = this.nodesSrv.getNode(guid);

    let calculation = `translate(calc(-50% + ${Math.round(
      this.node.width / 2
    )}px), 45px)`;

    if (this.isRtl) {
      calculation = `translate(calc(50% - ${Math.round(
        this.node.width / 2
      )}px), 45px)`;
    }

    this.childrenTransform = this.sanitizer.bypassSecurityTrustStyle(
      calculation
    );
  }

  onNodeBlur(event, nodeId) {
    const node = this.nodesSrv.getNode(nodeId);
    node.displayName = event.target.innerText;
  }

  toggleState = () => {
    if (this.node.root) {
      return;
    }
    this.knowledgeGraphService.activeNode.next(this.node);
  }

  getChildData() {
    this.node.toggle();
    this.node.getChildContent(this.node);
  }

  moreLessToggle(event, node) {
    event.stopPropagation();
    node.viewMore = !node.viewMore;
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
  }
}
