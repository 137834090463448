import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NodesListService } from './services/nodes-list.service';
import { KnowledgeGraphService } from '../knowledge-graph.service';
import { escape } from 'querystring';
import { DOCUMENT } from '@angular/common';
import TRANSFORM_CONFIG from './tree.constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tree-diagram',
  styleUrls: ['./tree.component.scss'],
  templateUrl: './tree.component.html'
})
export class TreeComponent implements OnInit,OnDestroy {
  public nodes;
  private config = {
    nodeWidth: 320,
    /*nodeHeight: 62*/
  };
  private paneDragging = false;
  private paneTransformState;
  private zoom = 1;
  private paneX = 0;
  private paneY = 0;
  public nodesSrv: NodesListService = new NodesListService();
  public fullMode = false;
  private activeSubscriptions:Subscription = new Subscription();
  public visible = false;
  public graphTimeoutId: any = '';
  @ViewChild('graphEnd') container: ElementRef;

  public get paneTransform() {
    return this.paneTransformState;
  }

  public set paneTransform(value) {
    this.paneTransformState = value;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private knowledgeGraphService: KnowledgeGraphService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
  ) {
  }


  ngOnInit(): void {
    this.activeSubscriptions.add(
      this.knowledgeGraphService.graphPosition.subscribe(position => {
      this.transform(position.paneX, position.paneY, position.zoom);
    }));
    this.graphTimeoutId = setTimeout(() => {
      this.scrollGraph();
    }, 800);
  }
  scrollGraph() {
    this.container.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    this.visible = true;
  }

  @Input() set data(data: any[]) {
    this.nodes = this.nodesSrv.loadNodes(data, this.config);
  }

  @Output() nodeClick: EventEmitter<any> = new EventEmitter();

  public get nodeMaker() {
    return this.nodesSrv.makerNode();
  }

  public newNode() {
    this.nodesSrv.newNode();
  }

  public onmousedown() {
    this.paneDragging = true;
  }

  public onmousemove(event) {
    if (this.paneDragging) {
      const { movementX, movementY } = event;
      this.paneX += movementX;
      this.paneY += movementY;
    }
  }

  public onmouseup() {
    this.paneDragging = false;
    this.makeTransform();
  }

  zoomIn() {
    this.zoom = Math.min(this.zoom + TRANSFORM_CONFIG.ZOOM_LEVEL, TRANSFORM_CONFIG.MAX_ZOOM);
    this.makeTransform();
  }

  zoomOut() {
    this.zoom = Math.max(this.zoom - TRANSFORM_CONFIG.ZOOM_LEVEL, TRANSFORM_CONFIG.ZOOM_LEVEL);
    this.makeTransform();
  }


  public transform(paneX: number, paneY: number, zoom: number) {
    this.paneTransform = this.sanitizer.bypassSecurityTrustStyle(
      `translate(${paneX}px, ${paneY}px) scale(${zoom})`
    );
  }

  public makeTransform() {
    this.knowledgeGraphService.graphPosition.next({ paneX: this.paneX, paneY: this.paneY, zoom: this.zoom })
  }
  onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case TRANSFORM_CONFIG.LEFT_ARROW:
        this.moveRight();
        break;
      case TRANSFORM_CONFIG.RIGHT_ARROW:
        this.moveLeft();
        break;
      case TRANSFORM_CONFIG.UP_ARROW:
        this.moveDown();
        break;
      case TRANSFORM_CONFIG.DOWN_ARROW:
        this.moveUp();
        break;
    }
  }

  moveLeft() {
    this.paneX -= TRANSFORM_CONFIG.MOVE;
    this.makeTransform();
  }

  moveRight() {
    this.paneX += TRANSFORM_CONFIG.MOVE;
    this.makeTransform();
  }

  moveUp() {
    this.paneY = Math.min(TRANSFORM_CONFIG.THRESHOLD_UP, this.paneY + TRANSFORM_CONFIG.MOVE);
    this.makeTransform();
  }

  moveDown() {
    this.paneY = Math.max(-TRANSFORM_CONFIG.THRESHOLD_UP, this.paneY - TRANSFORM_CONFIG.THRESHOLD_UP);
    this.makeTransform();
  }

  public preventMouse(event) {
    event.stopPropagation();
  }

  async enableFullMode() {
    await (this.document.fullscreenElement ? this.document.exitFullscreen() : this.elementRef.nativeElement.requestFullscreen());
    this.fullMode = !!this.document.fullscreenElement;
  }

  @HostListener("document:fullscreenchange", ["$event"])
  onKeydownHandler() {
    if (!this.document.fullscreenElement) {
      this.fullMode = false;
    }
  }

  public onmousewheel(event) {
    /* let delta;
 
    event.preventDefault();
    delta = event.detail || event.wheelDelta;
    this.zoom += delta / 1000 / 2;
    this.zoom = Math.min(Math.max(this.zoom, 0.2), 3);

     this.makeTransform(); */
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.unsubscribe();
    clearTimeout(this.graphTimeoutId);
  }
}
