<div class="my-knowledge-graph-block">
    <div class="knowledge-graph-head" [ngClass]="{'stickyTop': isStickyTop}">
        <h4 class="heading-content">
            myKnowledge Graph <img data-toggle="modal" aria-label="progress index info button" data-target="#graphModal" src="/assets/images/info-icon.svg" />
            <span class="sub-heading">{{activeView.LABEL}} <img data-toggle="modal" [attr.data-target]="'#'+activeView.ID+'Performance'" aria-label="progress index info button" src="/assets/images/info-icon.svg" alt="Graph View" /></span>
        </h4>
        <div class="filters-container">
            <div class="courseDropdown">
                <span class="primary-tag" *ngIf="activeGoal?.isPrimary">Primary</span>
                <div class="dropdown subject-dropdown">
                    <button class="btn btn-goalList dropdown-toggle" type="button" id="goalListMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <p class="selectcoursetext">{{activeGoal?.name}}</p>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="goalListMenuButton">
                        <div class="goalList">
                            <a role="button" *ngFor="let goals of userGoals" class="dropdown-item"
                                [ngClass]="{'active':goals.courseTypeId === activeGoal.courseTypeId}"
                                (click)="setActiveGoals(goals)">
                                <span>{{goals?.name}}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-tabs performance-tab-view" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" *ngFor="let view of viewList">
                        <span class="nav-link" data-toggle="tab" (click)="setActiveView(view)" aria-selected="true" [ngClass]="{'active':activeView.ID === view.ID}">{{view.LABEL}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="graph-performance-block">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active">
                <div class="graph-view-block" *ngIf="activeGoal && absoluteNodeList.length > 1  && relativeNodeList.length > 1; else emptyScreen">
                    <tree-diagram [ngClass]="{'d-none':activeView.ID !==  GRAPH_PARAMS.VIEW.ABSOLUTE.ID}" *ngIf="showPerformance[GRAPH_PARAMS.VIEW.ABSOLUTE.ID]" [data]="absoluteNodeList"></tree-diagram>
                    <tree-diagram [ngClass]="{'d-none':activeView.ID !==  GRAPH_PARAMS.VIEW.RELATIVE.ID}" *ngIf="showPerformance[GRAPH_PARAMS.VIEW.RELATIVE.ID]" [data]="relativeNodeList"></tree-diagram>
                </div>
                <ng-template #emptyScreen>
                    <div class="empty-state-container">
                        <div class="empty-state-block">
                            <img src="assets/img/knowledgeGraphEmptyScreen.svg" alt="Empty Screen" />
                            <div class="w-100 empty-state-content text-center">
                                <h4 class="title">No Data Found</h4>
                                <p>The performance has not been evaluated yet</p>
                            </div>
                        </div>
                    </div>
                </ng-template >
            </div>
        </div>
    </div>
</div>

<!-- Knowledge Graph info modal -->
<div class="modal fade small-modal" tabindex="-1" id="graphModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <img src="/assets/images/myKnowledgeGraph-icon.svg" />
                <h3>myKnowledge Graph</h3>
                <p>Knowledge Graph displays your performance across subject, chapter, and concept levels based on proficiency for comprehensive understanding and improvement.</p>
            </div>
            <div class="modal-footer single-btn">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Got It</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade small-modal" tabindex="-1" id="absolutePerformance">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <img src="/assets/images/knowledge-graph-mobile/absolutePerformance.svg"  alt="Absolute Performance">
                <h3>Absolute Performance</h3>
                <p>Absolute Performance displays your performance across subject, chapter, and concept levels based on your overall attempts on the platform</p>
            </div>
            <div class="modal-footer single-btn">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Got It</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade small-modal" tabindex="-1" id="relativePerformance">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-body">
                <img src="/assets/images/knowledge-graph-mobile/relativePerformance.svg" alt="Relative performance">
                <h3>Relative Performance</h3>
                <p>Relative Performance shows your performance across the subject, chapter, and concept levels based on your attempts as per your myGoal on the platform.</p>
            </div>
            <div class="modal-footer single-btn">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Got It</button>
            </div>
        </div>
    </div>
</div>