export const MONTHS_MAP = {
    'JAN': '01',
    'FEB': '02',
    'MAR': '03',
    'APR': '04',
    'MAY': '05',
    'JUN': '06',
    'JUL': '07',
    'AUG': '08',
    'SEP': '09',
    'OCT': '10',
    'NOV': '11',
    'DEC': '12'
};

export const VIEW_DETAILS_CAROUSEL: any = {
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    autoWidth: true,
    navText: [
        `<div class='nav-btn prev-slide'><img src='/assets/images/myClasses/chevron-small-down.svg'/></div>`,
        `<div class='nav-btn next-slide'><img src='/assets/images/myClasses/chevron-small-down.svg'/></div>`
    ],
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 4
        }
    }
};

export const ZOOM_CTA_TITLE = {
    live: 'Click to Join',
    upcoming: 'Link will be enabled',
    past: 'Class Completed'
};

export const CLASS_STATUS = {
    liveClass: 'live',
    upcomingClass: 'upcoming',
    pastClass: 'past'
};

export const dailyViewCarouselSettings: any = {
    loop: false,
    nav: false,
    dots: false,
    navSpeed: 100,
    autoplay: false,
    items: 7,
    slideBy: 7,
    touchDrag: false,
    mouseDrag: false,
    navText: [
        `<div class='nav-btn prev-slide'><img src='/assets/images/myClasses/chevron-left.svg'/></div>`,
        `<div class='nav-btn next-slide'><img src='/assets/images/myClasses/chevron-left.svg'/></div>`
    ],
    responsive: {
        0: {
            items: 4
        },
        600: {
            items: 7
        },
        1000: {
            items: 7
        }
    }
};

export const weekViewCarouselSettings: any = {
    loop: false,
    nav: false,
    dots: false,
    navSpeed: 100,
    autoplay: false,
    touchDrag: false,
    mouseDrag: false,
    items: 7,
    slideBy: 7,
    navText: [
        `<div class='nav-btn prev-slide'><img src='/assets/images/myClasses/arrow_blue.svg'/></div>`,
        `<div class='nav-btn next-slide'><img src='/assets/images/myClasses/arrow_blue.svg'/></div>`
    ],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 3
        },
        700: {
            items: 7
        }
    }
};
export const WEEK_RENDERING_INFO: any = {
    MAX_DAYS_IN_WEEEK: 7,
    DEFAULT_INDEX: 0
};

export const TIMETABLE_VIEW: any = {
    daily: 'daily',
    weekly: 'weekly'
};

export const MYCLASSES_VIEW: any = {
    attendance: 'attendance',
    timetable: 'timetable',
    viewDetail: 'viewDetails',
    markAttendance: 'allowAttendanceMarking',
    attendanceUpdate: 'attendanceStatusUpdated',
};

export const CAROUSEL_TYPES: any = {
    daily: 'dailyview',
    weekly: 'weeklyview'
};

export const MYCLASSES_BASE_URL = '/home/myclasses';

export const CLASS_MODE: any = {
    OFFLINE: ['Offline', 'offline'],
    ONLINE: ['Online', 'online'],
    BOTH: ['Offline + Online', 'offline + online', 'online_offline', 'offline_online'],
}

export const CTA_TITLE = { viewDetail: 'View Details', startNow: 'Start Now' };

export const ATTENDANCE_INFO = ['absent', 'present'];

export enum TEST_STATUS {
    PAST = 'Past',
    UPCOMING = 'Upcoming',
    LIVE = 'Live',
    CLASS = 'class'
};

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const WEEKSDAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const CLASSES_TYPE = {
    PREV: 'previous',
    NEXT: 'next'
};

export const STATUS_CODES = {
    SUCCESS: 200,
    SERVER_ERROR: 500,
    NOT_FOUND: 404,
    FORBIDDEN: 403
};

export const ATTENDANCE_VALUE = ['Present', 'Absent'];
export const OBJECTIVE: string = 'objective';
export const SUBJECTIVE: string = 'subjective';
export const IMAGE_BASE_URL: string = '/assets/images/myPlan';
export const ARCHIVES_NOTES = ['archiveObjective', 'archiveSubjective'];
export const CONTENT_TYPES = ['studyNotes', 'video', 'audio', 'practice', 'test', 'pdfNotes'];
export const CONTENT_STATUS = ['finished'];
export const VIEW_LEVEL = 'chapter';

export const contentSubTypeMap: any = {
    'cppSubjective': 'CPPSUBJECTIVE',
    'cppObjective': 'CPPOBJECTIVE',
    'workbookSubjective': 'WORKBOOKSUBJECTIVE',
    'workbookObjective': 'WORKBOOKOBJECTIVE',
    'assignmentSubjective': 'ASSIGNMENTSUBJECTIVE',
    'assignmentsSubjective': 'ASSIGNMENTSUBJECTIVE',
    'assignmentObjective': 'ASSIGNMENTOBJECTIVE',
    'assignmentsObjective': 'ASSIGNMENTOBJECTIVE',
    'archiveSubjective': 'ARCHIVESUBJECTIVE',
    'archiveObjective': 'ARCHIVEOBJECTIVE',
}

export const contentTypeData: any = {
    'studyNotes': {
        'icon': IMAGE_BASE_URL + '/notes-icon.svg',
        'title': 'NOTES',
        'text': 'Read',
        subType: {
            'CPPSUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/cpp-subjective-icon.svg',
                'title': 'CPP (Subjective)',
                'text': 'Read',
            },
            'CPPOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/cpp-objective-icon.svg',
                'title': 'CPP (Objective)',
                'text': 'Practice',
            },
            'WORKBOOKSUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/workbook-subjective-icon.svg',
                'title': 'Workbook (Subjective)',
                'text': 'Read',
            },
            'WORKBOOKOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/workbook-objective-icon.svg',
                'title': 'Workbook (Objective)',
                'text': 'Practice',
            },
            'ASSIGNMENTSUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/assignment-subjective-icon.svg',
                'title': 'Assignment (Subjective)',
                'text': 'Read',
            },
            'ASSIGNMENTOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/assignment-objective-icon.svg',
                'title': 'Assignment (Objective)',
                'text': 'Practice',
            },
            'ARCHIVESUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/archiveSubjective.svg',
                'title': 'Archive (Subjective)',
                'text': 'Read',
            },
            'ARCHIVEOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/archiveObjective.svg',
                'title': 'Archive (Objective)',
                'text': 'Practice',
            },
        }
    },
    'pdfNotes': {
        'icon': IMAGE_BASE_URL + '/pdfNotes.svg',
        'title': 'PDF NOTES',
        'text': 'Read',
        subType: {
            'WORKBOOK': {
                'icon': IMAGE_BASE_URL + '/workbook-web.svg',
                'title': 'WORKBOOK',
                'text': 'Read',
            },
        }
    }

};

export const practiceSetTypeMap: any = {
    'CPPSUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/cpp-subjective-icon.svg`,
        'text': SUBJECTIVE,
    },
    'CPPOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/cpp-objective-icon.svg`,
        'text': OBJECTIVE,
    },
    'WORKBOOKSUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/workbook-subjective-icon.svg`,
        'text': SUBJECTIVE,
    },
    'WORKBOOKOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/workbook-objective-icon.svg`,
        'text': OBJECTIVE,
    },
    'ASSIGNMENTSUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/assignment-subjective-icon.svg`,
        'text': SUBJECTIVE,
    },
    'ASSIGNMENTOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/assignment-objective-icon.svg`,
        'text': OBJECTIVE,
    },
    'ARCHIVESUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/archiveSubjective.svg`,
        'text': SUBJECTIVE,
    },
    'ARCHIVEOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/archiveObjective.svg`,
        'text': OBJECTIVE,
    },
};

export const NOTES_TYPES_IMAGES = {
    'studyNotes': '/myPlan/notes-icon.svg',
    'video': '/myPlan/Video.svg',
    'audio': '/learn/audio/audio-player.svg',
    'practice': '/myPlan/Practice.svg',
    'test': '/myPlan/Test.svg'
};

export const FUSION_CHART_DATA = {
    MOBILE_DEVICE: /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i,
    TABLET: /tablet/i,
    BG_IMAGE: '/assets/images/dialGrapg-web.svg',
    MAX_PROFICIENCY: 100,
    BG_RED_UPPER: 61,
    BG_WARNING_UPPER: 81,
    BG_WARNING_LOWER: 60,
    BG_GREEN_LOWER: 80,
    BG_RED: 'bg-red',
    BG_WARNING: 'bg-warning',
    BG_GREEN: 'bg-green',
};

export const DATA_SOURCE = {
    chart: {
        caption: '',
        lowerLimit: '0',
        upperLimit: '200',
        showValue: '1',
        numberSuffix: '%',
        theme: 'fusion',
        showToolTip: '0',
        showTickMarks: '0',
        showTickValues: '0',
        valueBelowPivot: '1',
        pivotRadius: '7',
        pivotFillColor: '#393939',
        pivotFillAlpha: '100',
        pivotFillMix: '#393939',
        baseFontSize: '23',
        baseFontColor: '#666666',
        bgImage: ''
    },
    colorRange: {
        color: [{
            minValue: '0',
            maxValue: '66',
            code: '#ffffff',
            alpha: '0'
        }, {
            minValue: '67',
            maxValue: '132',
            code: '#ffffff',
            alpha: '0'
        }, {
            minValue: '133',
            maxValue: '199',
            code: '#ffffff',
            alpha: '0'
        }]
    },
    dials: {
        dial: [{
            value: '100',
            bgColor: '#393939',
            borderColor: '#393939',
            borderThickness: '1',
            borderAlpha: '100',
        }]
    }
};

export const DEFAULT_FILTERS = {
    SUBJECTS: 'All Subjects',
    PHASES: 'All'
};


export const TEST_TYPE_TO_DISPLAY_NAME_MAPPING = {
    'Full Test': 'fullTest',
    'Part Test': 'partTest',
    'Part test': 'partTest',
    'Half Course Test': 'halfTest',
    'Chapter Test': 'chapterTest',
    'Concept Test': 'conceptTest',
    'Sample Test': 'sampleTest',
    'Phase Test': 'phaseTest',
    'Unit Test': 'unitTest',
    'Combined Test': 'halfTest',
    'unit': 'unitTest',
    'Main Test': 'fullTest'
};

export const MEETING_LINK = 'http://fiitjee.zoom.us/';
export const ONLINE_LOCATION = 'On Zoom';
export const LINK_COPIED_MESSAGE = 'Link Copied!!!';
export const CLASS_DETAILS_CHARACTER_LIMIT = 256;
