import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import GRAPH_PARAMS from './graph-view.constants';
import { KnowledgeGraphService } from '../knowledge-graph.service';
import { Subscription } from 'rxjs';
import { STATUS_CODES } from 'src/app/home-page/cards-detail/right-selected-card/my-classes/my-classes.constant';
import { AlertService } from 'src/app/shared/services/alert.service';
import KG_PRAMS from '../knowlege-graph.constants';
import { Router } from '@angular/router';
import TRANSFORM_CONFIG from '../tree/tree.constant';

@Component({
    selector: 'graph-view',
    templateUrl: './graph-view.component.html',
    styleUrls: ['./graph-view.component.scss']
})
export class GraphViewComponent implements OnInit, OnDestroy {
    public readonly GRAPH_PARAMS = GRAPH_PARAMS;
    public activeView = GRAPH_PARAMS.VIEW.RELATIVE;
    public isStickyTop: boolean = false;
    public userGoals: any[];
    public activeGoal: any = {};
    public graphData: any = {};
    public viewList = Object.values(GRAPH_PARAMS.VIEW);
    public updateGraph: any = {};
    private activeSubscriptions: Subscription = new Subscription();
    private fetchedNode = {};
    private fetchContent = [GRAPH_PARAMS.CONTENT_TYPE.CHAPTER, GRAPH_PARAMS.CONTENT_TYPE.SUBJECT];
    public animateCount: number = 0;
    private activeSubject: any = [];
    public absoluteNodeList = [];
    public relativeNodeList = [];
    public showPerformance = {};
    @HostListener('document:scroll', ['$event'])
    onWindowScroll() {
        this.isStickyTop = window.scrollY > 10;
    }

    constructor(private knowledgeGraphService: KnowledgeGraphService, private alertService: AlertService, private router: Router) { }

    ngOnInit(): void {
        this.getUserGoals();
        this.showPerformance = {
            [GRAPH_PARAMS.VIEW.RELATIVE.ID]: true,
        };
    }

    initGraphState() {
        if (!Object.keys(this.knowledgeGraphService.graphActiveState).length)
            return;
        this.activeView = this.knowledgeGraphService.graphActiveState?.activeView;
        this.graphData = this.knowledgeGraphService.graphActiveState?.graphData;
        this.fetchContent = this.knowledgeGraphService.graphActiveState?.fetchContent;
        this.activeGoal = this.knowledgeGraphService.graphActiveState?.activeGoal;
        this.userGoals = this.knowledgeGraphService.graphActiveState?.userGoals;
        this.fetchedNode = this.knowledgeGraphService.graphActiveState?.fetchedNode;
    }

    padString(str: string, length: number) {
        if (str?.length >= length)
            return str;
        const totalPadding = length - str.length;
        const left = Math.floor(totalPadding / 2);
        const right = totalPadding - left;
        return ' '.repeat(left) + str + ' '.repeat(right);
    }

    formatContentCount(contentList: any[]) {
        let maxLength = KG_PRAMS.UTILITY_PRAMS.DEFAULT_LENGTH;
        this.animateCount++;
        contentList.forEach((content, index: number) => {
            switch (content.type) {
                case GRAPH_PARAMS.CONTENT_TYPE.SUBJECT:
                    content.count = content.chapterCount;
                    maxLength = KG_PRAMS.UTILITY_PRAMS.SUBJECT_LENGTH;
                    content.name = content.name.length > maxLength ? content.name.substring(0, maxLength) + KG_PRAMS.UTILITY_PRAMS.ELLIPSE : content.name;
                    break;

                case GRAPH_PARAMS.CONTENT_TYPE.CONCEPT:
                case GRAPH_PARAMS.CONTENT_TYPE.CHAPTER:
                    content.count = content.conceptCount;
                    content.name = `${index + 1}. ${content.name}`;
                    content.animateCount = this.animateCount;
                    break;
            }
            content.childContent = GRAPH_PARAMS.NODE_CHILD[content.type];
            content.dimension = GRAPH_PARAMS.NODE_DATA.CHILD.DIMENSION;
            content.skill = this.padString(content?.capability?.value, KG_PRAMS.UTILITY_PRAMS.MAX_SKILL);
            content.showArrow = content.type === GRAPH_PARAMS.CONTENT_TYPE.CONCEPT && content?.questionStats?.totalQuestionCount;
        })

        return JSON.parse(JSON.stringify(contentList));
    }

    expandNode(node: any) {
        if (node.type === GRAPH_PARAMS.CONTENT_TYPE.CONCEPT) {
            this.router.navigate([`knowledge-graph/concepts`], {
                queryParams: { conceptId: node.id, goalId: this.activeGoal.courseId, view: this.activeView.ID, subjectId: this.activeSubject.id, chapterId: node.chapterId }
            });
            return;
        } else if (node.type === GRAPH_PARAMS.CONTENT_TYPE.SUBJECT) {
            this.activeSubject = { ...node };
        }
        node.expanded = !node.expanded;
        const fetchedKey = node.id + '_' + this.activeView.ID;
        if (this.fetchContent.includes(node.type) && !this.fetchedNode[fetchedKey]) {
            this.getNodeData(node);
        } else {
            this.graphData = JSON.parse(JSON.stringify(this.graphData));
        }
    }

    getUserGoals() {
        this.activeSubscriptions.add(this.knowledgeGraphService.getUserGoal().subscribe((res: any) => {
            if (res?.code == STATUS_CODES.SUCCESS) {
                this.userGoals = res.data?.courses || [];
                this.userGoals.sort((firstCourse, secondCourse) => secondCourse.isPrimary - firstCourse.isPrimary);
                this.setActiveGoals(this.userGoals.find((course) => course.isPrimary));
            } else {
                this.activeGoal = null;
                this.alertService.showErrorMessage(res?.message || KG_PRAMS.ERROR_MESSAGE.GOALS);
            }
        }, (error) => {
            this.activeGoal = null;
            this.alertService.showErrorMessage(error?.message || KG_PRAMS.ERROR_MESSAGE.GOALS);
        }
        ));
    }

    formatNode(node: any) {
        node.guid = node.id;
        node.displayName = node.name;
        node.children = [];
        node.isChildrenPresent = node.type !== GRAPH_PARAMS.CONTENT_TYPE.CONCEPT;
        return node;
    }

    getNodeData = (node: any) => {
        const payload: any = { courseId: this.activeGoal.courseId, entityTypes: [GRAPH_PARAMS.NODE_CHILD[node.type]], type: node.viewType };
        switch (node.type) {
            case GRAPH_PARAMS.CONTENT_TYPE.CHAPTER:
                payload.chapterIds = [node.id];
                break;
            case GRAPH_PARAMS.CONTENT_TYPE.SUBJECT:
                payload.subjectIds = [node.id];
                this.activeSubject = node;
                break;
            case GRAPH_PARAMS.CONTENT_TYPE.CONCEPT:
                node?.questionStats?.totalQuestionCount && this.router.navigate([`knowledge-graph/concepts`], {
                queryParams: { conceptId: node.id, goalId: this.activeGoal.courseId, view: node.viewType, subjectId: this.activeSubject.id, chapterId: node.chapterId }
                });
        }
        if (node.children.size || node.type === GRAPH_PARAMS.CONTENT_TYPE.CONCEPT) {
            return;
        }
        this.activeSubscriptions.add(this.knowledgeGraphService.getContentList(payload).subscribe((res: any) => {
            if (res?.code == STATUS_CODES.SUCCESS) {
                node.children = (res?.data?.knowledgeGraphEntities || []).map((currentNode: any, index: number) => {
                    currentNode.shortName = currentNode?.name?.substr(0, TRANSFORM_CONFIG.SHORT_NAME_LENGTH);
                    currentNode.parentId = node.id;
                    currentNode.viewType = node.viewType;
                    currentNode.sno = index + 1;
                    this.formatNode(currentNode);
                    node.viewType === GRAPH_PARAMS.VIEW.ABSOLUTE.ID ? this.absoluteNodeList.push(currentNode) : this.relativeNodeList.push(currentNode);
                    currentNode.getChildContent = this.getNodeData;
                    !node.root && node.addChild(currentNode);
                    return currentNode.id;
                });
            } else {
                this.alertService.showErrorMessage(res?.message || KG_PRAMS.ERROR_MESSAGE.CONTENT);
            }
        }, (error) =>
            this.alertService.showErrorMessage(error?.message || KG_PRAMS.ERROR_MESSAGE.CONTENT)
        ));
    }

    setActiveGoals(newGoal: any) {
        this.activeGoal = newGoal;
        this.graphData = {}
        this.fetchedNode = {};
        const absoluteGoalView = this.formatGoalView(GRAPH_PARAMS.VIEW.ABSOLUTE.ID);
        this.absoluteNodeList = [absoluteGoalView];
        const relativeGoalView = this.formatGoalView(GRAPH_PARAMS.VIEW.RELATIVE.ID);
        this.relativeNodeList = [relativeGoalView];
        this.getNodeData(absoluteGoalView);
        this.getNodeData(relativeGoalView);
    }

    formatGoalView(view: string) {
        const newActiveGoalView = JSON.parse(JSON.stringify(this.activeGoal));
        newActiveGoalView.viewType = view;
        newActiveGoalView.root = true;
        newActiveGoalView.id = newActiveGoalView.courseTypeId;
        newActiveGoalView.expanded = true;
        //newActiveGoalView.capability = { background: GRAPH_PARAMS.NODE_DATA.ROOT.BACKGROUND };
        newActiveGoalView.dimension = GRAPH_PARAMS.NODE_DATA.ROOT.DIMENSION;
        newActiveGoalView.root = true;
        newActiveGoalView.type = GRAPH_PARAMS.CONTENT_TYPE.GOAL;
        this.formatNode(newActiveGoalView);
        return newActiveGoalView;
    }

    setActiveView(newView: any) {
        this.activeView = newView;
        this.showPerformance = {
            [newView.ID]: true,
            ...this.showPerformance,
        };
    }

    ngOnDestroy(): void {
        this.activeSubscriptions.unsubscribe();
        this.knowledgeGraphService.graphActiveState = {
            activeView: this.activeView,
            graphData: this.graphData,
            fetchContent: this.fetchContent,
            activeGoal: this.activeGoal,
            userGoals: this.userGoals,
            fetchedNode: this.fetchedNode,
        };
    }
}