export const OBJECTIVE: string = 'objective';
export const SUBJECTIVE: string = 'subjective';
export const IMAGE_BASE_URL: string = '/assets/images/myPlan';



export const contentSubTypeMap: any = {
    'cppSubjective': 'CPPSUBJECTIVE',
    'cppObjective': 'CPPOBJECTIVE',
    'workbookSubjective': 'WORKBOOKSUBJECTIVE',
    'workbookObjective': 'WORKBOOKOBJECTIVE',
    'assignmentSubjective': 'ASSIGNMENTSUBJECTIVE',
    'assignmentsSubjective': 'ASSIGNMENTSUBJECTIVE',
    'assignmentObjective': 'ASSIGNMENTOBJECTIVE',
    'assignmentsObjective': 'ASSIGNMENTOBJECTIVE',
    'archiveSubjective': 'ARCHIVESUBJECTIVE',
    'archiveObjective': 'ARCHIVEOBJECTIVE',
}

export const contentTypeData: any = {
    'studyNotes': {
        'icon': IMAGE_BASE_URL + '/notes-icon.svg',
        'title': 'NOTES',
        'text': 'Read',
        subType: {
            'CPPSUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/cpp-subjective-icon.svg',
                'title': 'CPP (Subjective)',
                'text': 'Read',
            },
            'CPPOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/cpp-objective-icon.svg',
                'title': 'CPP (Objective)',
                'text': 'Practice',
            },
            'WORKBOOKSUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/workbook-subjective-icon.svg',
                'title': 'Workbook (Subjective)',
                'text': 'Read',
            },
            'WORKBOOKOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/workbook-objective-icon.svg',
                'title': 'Workbook (Objective)',
                'text': 'Practice',
            },
            'ASSIGNMENTSUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/assignment-subjective-icon.svg',
                'title': 'Assignment (Subjective)',
                'text': 'Read',
            },
            'ASSIGNMENTOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/assignment-objective-icon.svg',
                'title': 'Assignment (Objective)',
                'text': 'Practice',
            },
            'ARCHIVESUBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/archiveSubjective.svg',
                'title': 'Archive (Subjective)',
                'text': 'Read',
            },
            'ARCHIVEOBJECTIVE': {
                'icon': IMAGE_BASE_URL + '/archiveObjective.svg',
                'title': 'Archive (Objective)',
                'text': 'Practice',
            },
        }
    },
    'pdfNotes': {
        'icon': IMAGE_BASE_URL + '/pdfNotes.svg',
        'title': 'PDF NOTES',
        'text': 'Read',
        subType: {
            'WORKBOOK': {
                'icon': IMAGE_BASE_URL + '/workbook-web.svg',
                'title': 'WORKBOOK',
                'text': 'Read',
            },
        }
    }

}


export const practiceSetTypeMap: any = {
    'CPPSUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/cpp-subjective-icon.svg`,
        'text': SUBJECTIVE,
    },
    'CPPOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/cpp-objective-icon.svg`,
        'text': OBJECTIVE,
    },
    'WORKBOOKSUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/workbook-subjective-icon.svg`,
        'text': SUBJECTIVE,
    },
    'WORKBOOKOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/workbook-objective-icon.svg`,
        'text': OBJECTIVE,
    },
    'ASSIGNMENTSUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/assignment-subjective-icon.svg`,
        'text': SUBJECTIVE,
    },
    'ASSIGNMENTOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/assignment-objective-icon.svg`,
        'text': OBJECTIVE,
    },
    'ARCHIVESUBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/archiveSubjective.svg`,
        'text': SUBJECTIVE,
    },
    'ARCHIVEOBJECTIVE': {
        'icon': `${IMAGE_BASE_URL}/archiveObjective.svg`,
        'text': OBJECTIVE,
    },
};

export const CONTENT_SUBTYPES_TITLE = {
    cppSubjective: 'CPP (Subjective)',
    cppObjective: 'CPP (Objective)',
    workbookSubjective: 'Workbook (Subjective)',
    workbookObjective: 'Workbook (Objective)',
    assignmentSubjective: 'Assignment (Subjective)',
    assignmentsSubjective: 'Assignment (Subjective)',
    assignmentObjective: 'Assignment (Objective)',
    assignmentsObjective: 'Assignment (Objective)',
    archiveSubjective: 'Archive (Subjective)',
    archiveObjective: 'Archive (Objective)',
};