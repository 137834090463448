import { HighlightSearch } from './pipes/highlight-search.pipe';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { HttpService } from './services/http.service';
import { UtilityService } from './services/utility.service';
import { ExtraEdgeService } from './services/extra-edge.service';
import { CleverTapService } from './services/clever-tap.service';
import { NotificationService } from './services/notification.service';

import { CaptchaDirective } from './directives/captcha.directive';

import { LoaderComponent } from './components/loader/loader.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TransferToPaytmComponent } from './components/transfer-to-paytm/transfer-to-paytm.component';
import { UnderDevelopmentComponent } from './components/under-development/under-development.component';
import { HeaderAfterLoginComponent } from './components/header-after-login/header-after-login.component';
import { ShareReferralPopupComponent } from '../profile/my-cash-card/share-referral-popup/share-referral-popup.component';
import { ReportAbuseComponent } from './components/report-abuse/report-abuse.component';
import { ConvertSecondsPipe } from './pipes/convert-seconds.pipe';
import { FormateTimePipe } from './pipes/formate-time.pipe';
import { OwlModule } from 'ngx-owl-carousel';
import { SeriousAttemptWarningComponent } from './serious-attempt-warning/serious-attempt-warning.component';
import { InfoPopupComponent } from './components/info-popup/info-popup.component';
import { HomeSearchComponent } from '../home-page/shared/home-search/home-search.component';
import { TagCreatorSharedComponent } from './components/tag-creator-shared/tag-creator-shared.component';
import { DoneWithRevisionComponent } from './components/done-with-revision/done-with-revision.component';
import { PageLockComponent } from './page-lock/page-lock.component';
import { HelpReportListComponent } from './components/help-report-list/help-report-list.component';
import { ReportAnIssueComponent } from './components/report-an-issue/report-an-issue.component';
import { HelpFeedbackComponent } from './components/help-feedback/help-feedback.component';
import { HelpLightBoxComponent } from './components/help-light-box/help-light-box.component';
import { IdeaReportListComponent } from './components/idea-report-list/idea-report-list.component';
import { ReportAnIssueIdeaComponent } from './components/report-an-issue-idea/report-an-issue-idea.component';
import { SelectedCardLoaderComponent } from '../home-page/shared/selected-card-loader/selected-card-loader.component';
import { HelpReopenPopupComponent } from './components/help-reopen-popup/help-reopen-popup.component';
import { HelpIssueTimelineComponent } from './components/help-issue-timeline/help-issue-timeline.component';
import { TestDetailsSyllabusComponent } from './components/test-details-syllabus/test-details-syllabus.component';
import { ProvideCommentsComponent } from './components/provide-comments/provide-comments.component';
import { ZoomDirective } from './directives/zoom.directive';
import { NotificationComponent } from './components/header-after-login/notification/notification.component';
import { ProfileDropdownComponent } from './components/header-after-login/profile-dropdown/profile-dropdown.component';
import { LogoutConfirmComponent } from './components/logout-confirm/logout-confirm.component';
import { DateDifferencePipe } from './pipes/date-difference.pipe';
import { LazyLoadingService } from './services/lazy-loading.service';
import { SideFilterListComponent } from '../test-results-analytics/side-filter-list/side-filter-list.component';
import { AutoProctorDialogComponent } from '../test/shared/components/auto-proctor-dialog/auto-proctor-dialog.component';
import { MultiselectfilterComponent } from '../test-results-analytics/side-filter-list/multiselectfilter/multiselectfilter.component';
import { BindFunctionToTemplate } from './pipes/bind-function-to-template.pipe';
import { NumberToAlphabetPipe } from './pipes/number-to-alphabet.pipe';
import { NumericalInputPadComponent } from './components/numerical-input-pad/numerical-input-pad.component';
import { NumberToOrdinalPipe } from './pipes/number-to-ordinal.pipe';
import { MathliveDirective } from './directives/mathlive.directive';
import { MyplanPopupComponent } from './components/myplan-popup/myplan-popup.component';
import { RegisterDeviceComponent } from './components/register-device/register-device.component';
import { VerifyNumberWithOtpComponent } from './components/verify-number-with-otp/verify-number-with-otp.component';
import { FeedbackPopupComponent } from './components/feedback-popup/feedback-popup.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DateFormat } from './pipes/format-date.pipe';
import { KnowledgeGraphModule } from '../knowledge-graph/knowledge-graph.module';
import { ProfileUserInfoComponent } from './components/profile-user-info/profile-user-info.component';

export function playerFactory() {
  return player;
}

export interface LazyLoadFile {
  url: string;
  type: 'stylesheet' | 'script';
}

export class LazyLoadFileImpl implements LazyLoadFile {
  url: string;
  type: 'stylesheet' | 'script';
  constructor(url: string, type: 'stylesheet' | 'script') {
    this.url = url;
    this.type = type;
  }
}

@NgModule({
  imports: [
    CommonModule, OwlModule, FormsModule, HttpClientModule, InfiniteScrollModule,
    RouterModule, ReactiveFormsModule, LottieModule.forRoot({ player: playerFactory }),
    KnowledgeGraphModule
  ],

  declarations: [
    CaptchaDirective, UnderDevelopmentComponent,
    ChangePasswordComponent, HeaderAfterLoginComponent, LoaderComponent,
    ShareReferralPopupComponent, TransferToPaytmComponent,
    ReportAbuseComponent, ConvertSecondsPipe, SeriousAttemptWarningComponent,
    HomeSearchComponent, PageLockComponent,
    HighlightSearch,
    HelpFeedbackComponent,
    NotificationComponent,
    ProfileDropdownComponent,
    LogoutConfirmComponent,
    InfoPopupComponent,
    FormateTimePipe,
    TagCreatorSharedComponent,
    DoneWithRevisionComponent,
    HelpReportListComponent,
    ReportAnIssueComponent,
    HelpLightBoxComponent,
    IdeaReportListComponent,
    ReportAnIssueIdeaComponent,
    SelectedCardLoaderComponent,
    HelpReopenPopupComponent,
    HelpIssueTimelineComponent,
    SideFilterListComponent,
    AutoProctorDialogComponent,
    MultiselectfilterComponent,
    // SafeHtmlPipe
    TestDetailsSyllabusComponent,
    ProvideCommentsComponent,
    ZoomDirective,
    BindFunctionToTemplate,
    NumberToAlphabetPipe,
    NumericalInputPadComponent,
    NumberToOrdinalPipe,
    DateDifferencePipe,
    MathliveDirective,
    MyplanPopupComponent,
    RegisterDeviceComponent,
    VerifyNumberWithOtpComponent,
    DateFormat,
    FeedbackPopupComponent,
    ProfileUserInfoComponent,
  ],
  exports: [
    CaptchaDirective, UnderDevelopmentComponent,
    LoaderComponent, HeaderAfterLoginComponent,
    ShareReferralPopupComponent, TransferToPaytmComponent,
    ReportAbuseComponent, ConvertSecondsPipe, SeriousAttemptWarningComponent,
    HighlightSearch, FormateTimePipe,
    InfoPopupComponent, HomeSearchComponent,
    TagCreatorSharedComponent, DoneWithRevisionComponent, PageLockComponent,
    ReportAnIssueComponent, ReportAnIssueIdeaComponent, HelpLightBoxComponent, IdeaReportListComponent,
    SelectedCardLoaderComponent, ZoomDirective,
    CommonModule, OwlModule, FormsModule, HttpClientModule, InfiniteScrollModule,
    RouterModule, ReactiveFormsModule, DateDifferencePipe, HelpReportListComponent,
    ChangePasswordComponent, HelpFeedbackComponent, NotificationComponent, ProfileDropdownComponent, LogoutConfirmComponent, SideFilterListComponent, AutoProctorDialogComponent, MultiselectfilterComponent, TestDetailsSyllabusComponent, 
    BindFunctionToTemplate, NumberToAlphabetPipe, NumericalInputPadComponent, NumberToOrdinalPipe, MyplanPopupComponent, MathliveDirective, RegisterDeviceComponent, VerifyNumberWithOtpComponent, DateFormat
  ],
  providers: [LazyLoadingService, DatePipe, UtilityService, HttpService, CleverTapService, ExtraEdgeService, NotificationService],
})
export class SharedModule { }
