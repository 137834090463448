import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphViewComponent } from './graph-view/graph-view.component';
import { ConceptQuestionComponent } from './concept-question/concept-question.component';
import { KnowledgeGraphComponent } from './knowledge-graph.component';
import { QuestionCardComponent } from './question-card/question-card.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule, Routes } from '@angular/router';
import { KnowledgeGraphService } from './knowledge-graph.service';
import { TreeDiagramModule } from './tree';

@NgModule({
  imports: [CommonModule, CarouselModule, TreeDiagramModule
  ],
  declarations: [KnowledgeGraphComponent,
    GraphViewComponent,
  ],
  providers: [KnowledgeGraphService]
})
export class KnowledgeGraphModule { }
